import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebaseConfig';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { GAD7_QUESTIONS, PHQ9_QUESTIONS, GAD2_QUESTIONS, PHQ2_QUESTIONS } from './utils/questions.js';
import { useLogout } from './utils/logout';
import JoiAppLogo from './joiapplogo.png'; 
import './css/ResultsPage.css';
 

ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale
);

const ResultsPage = () => {
    const navigate = useNavigate();
    const [gad7Data, setGad7Data] = useState([]);
    const [phq9Data, setPhq9Data] = useState([]);
    const [gad2Data, setGad2Data] = useState([]);
    const [phq2Data, setPhq2Data] = useState([]);
    const userPublicKey = localStorage.getItem('userPublicKey');
    const logout = useLogout();
    
    const [userId, setUserId] = useState(null);

 
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
           
                if (user) {
                    setUserId(user.uid);
                    fetchAnswers(user.uid);
                }
            
            
              else {
                navigate('/');
            }
        });

        return () => unsubscribe();
    }, [navigate]);


    const fetchAnswers = async (uid) => {
        if (!uid) {
            navigate('/');
            return;
        }

        const fetchCollectionData = async (collectionName) => {
            console.log("in fetchCollectionData collectionName: ", collectionName)
            const q = query(
                collection(db, 'users', uid, collectionName),
                orderBy('timestamp', 'desc')
            );
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        };

        try {
            const [gad7, phq9, gad2, phq2] = await Promise.all([
                fetchCollectionData('gad7'),
                fetchCollectionData('phq9'),
                fetchCollectionData('gad2'),
                fetchCollectionData('phq2')
            ]);

            setGad7Data(gad7);
            setPhq9Data(phq9);
            setGad2Data(gad2);
            setPhq2Data(phq2);
        } catch (error) {
            console.error("Error fetching answers: ", error);
        }
    };

  
    const processChartData = (answersData, questionsToShow = null) => {
        const chartData = {
            labels: [],
            datasets: []
        };

        if (answersData.length > 0) {
            const questions = questionsToShow || Object.keys(answersData[0].answers);
            chartData.labels = answersData.map(entry => new Date(entry.timestamp.seconds * 1000));

            questions.forEach(question => {
                const dataset = {
                    label: question,
                    data: answersData.map(entry => {
                        const answer = Number(entry.answers[question]);
                        return answer;
                    }),
                    fill: false,
                    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color for each dataset
                    tension: 0.1
                };
                chartData.datasets.push(dataset);
            });
        }

        return chartData;
    };

    const processTotalScoreChartData = (answersData, questions) => {
        const chartData = {
            labels: [],
            datasets: [
                {
                    label: 'Total Score',
                    data: [],
                    fill: false,
                    borderColor: '#FF5733', // Fixed color for total score chart
                    tension: 0.1
                }
            ]
        };

        if (answersData.length > 0) {
            chartData.labels = answersData.map(entry => new Date(entry.timestamp.seconds * 1000));

            chartData.datasets[0].data = answersData.map(entry => {
                const totalScore = questions.reduce((sum, question) => {
                    return sum + Number(entry.answers[question]);
                }, 0);
                return totalScore;
            });
        }

        return chartData;
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Score'
                }
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    font: {
                        size: 10
                    }
                }
            }
        }
    };

    const handleCalculateClick = () => {
        navigate('/calculating');
    };

    return (
        <div className="container">
       
            <div className="sub-header-container">
                <div className="header">
                    <div className="logo-container" onClick={() => navigate('/dashboard')}>
                        <img src={JoiAppLogo} alt="JoiApp Logo" className="logo" />
                        <span className="app-name">JoiApp</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    </div>
                    <button onClick={logout} className="logout-button">
                        로그아웃
                    </button>
                </div>
            
            </div>
            <div className="container" >
            Results Page
                    
                    {userId && <p className="public-key">User ID: {userId}</p>}
            </div>
    

            <div className="chart-container">
 
   
                <div className="section">
                    <h2 className="section-header">GAD-2 Data</h2>
                    {gad2Data.length > 0 ? (
                        <>
                            <h2 className="section-header">Total Score Over Time</h2>
                            <Line data={processChartData(gad2Data)} options={chartOptions} />
                        </>
                    ) : <p className="no-data-text">No GAD-2 data available.</p>}
                </div>
    
                <div className="section">
                    <h2 className="section-header">PHQ-2 Data</h2>
                    {phq2Data.length > 0 ? (
                        <>
                            <h2 className="section-header">Total Score Over Time</h2>
                            <Line data={processChartData(phq2Data)} options={chartOptions} />
                        </>
                    ) : <p className="no-data-text">No PHQ-2 data available.</p>}
                </div>
                <div className="section">
                    <h2 className="section-header">GAD-7 Data</h2>
                    {gad7Data.length > 0 ? (
                        <>
                            <h2 className="section-header">Total Score Over Time</h2>
                            <Line data={processChartData(gad7Data)} options={chartOptions} />
                        </>
                    ) : <p className="no-data-text">No GAD-7 data available.</p>}
                </div>
    
                <div className="section">
                    <h2 className="section-header">PHQ-9 Data</h2>
                    {phq9Data.length > 0 ? (
                        <>
                            <h2 className="section-header">Total Score Over Time</h2>
                            <Line data={processChartData(phq9Data)} options={chartOptions} />
                        </>
                    ) : <p className="no-data-text">No PHQ-9 data available.</p>}
                </div>
            </div>
            <div className="button-container">
                <button onClick={() => navigate('/questions')} className="button">Go Back to Questions Page</button>
                {phq2Data.length >= 2 && gad2Data.length >= 2 && (
                    <button onClick={handleCalculateClick} className="button">Go to Calculating Page</button>
                )}
            </div>
        </div>
    );
    
};
/*
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '20px',
        backgroundColor: 'linear-gradient(135deg, #003366 0%, #6699CC 100%)'
    },
    header: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    subHeaderContainer: {
        width: '100%',
        display: 'flex', 
        justifyContent: 'center', 
        marginBottom: '20px',
    },
    subHeader: {
        fontSize: '1.2rem',
    },
    chartContainer: {
        width: '100%',
        maxWidth: '1200px',
        backgroundColor: '#ffffff', // White background for chart container
        borderRadius: '10px',
        padding: '20px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow to make charts stand out
        marginBottom: '40px',
    },
    section: {
        marginBottom: '40px',
    },
    sectionHeader: {
        fontSize: '1.5rem',
        marginBottom: '10px',
    },
    noDataText: {
        fontSize: '1rem',
        color: 'grey',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '600px',
    },
    button: {
        padding: '10px 20px',
        fontSize: '1rem',
        margin: '10px',
        cursor: 'pointer',
        borderRadius: '5px',
        border: '1px solid #ccc',
        backgroundColor: '#f9f9f9',
        transition: 'background-color 0.3s ease',
    },
};

/*
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '20px',
    },
    header: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    subHeaderContainer: {

        width: '100%',
        display: 'flex', justifyContent: 'center', marginBottom: '20px' 
    },
    subHeader: {
        fontSize: '1.2rem',
    },
    chartContainer: {
        width: '100%',
        maxWidth: '1200px',
    },
    section: {
        marginBottom: '40px',
    },
    sectionHeader: {
        fontSize: '1.5rem',
        marginBottom: '10px',
    },
    noDataText: {
        fontSize: '1rem',
        color: 'grey',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '600px',
    },
    button: {        padding: '10px 20px',
        fontSize: '1rem',
        margin: '10px',
        cursor: 'pointer',
        borderRadius: '5px',
        border: '1px solid #ccc',
        backgroundColor: '#f9f9f9',
        transition: 'background-color 0.3s ease',
    },
};
*/

export default ResultsPage;

       
